import { LOGOUT_USER, ENV_CHANGE } from "../types/authTypes";
import {
  FETCH_SUBSCRIPTIONS,
  ADD_SUBSCRIPTION,
  DELETE_SUBSCRIPTIONS,
  UPDATE_SUBSCRIPTIONS,
  FETCH_BUNDLES,
  ADD_BUNDLE,
  EDIT_BUNDLE,
  DELETE_BUNDLE
} from "../types/subscriptionTypes";
 
 const INITIAL_STATE = {
   data: [],
   bundles: [],
   total: 0
 };


 
 // eslint-disable-next-line import/no-anonymous-default-export
 export default (state = INITIAL_STATE, action) => {
  const addSub = (state, action) => {
    const newSub = action.payload;

    const newSubList = state.data.slice(0);
    newSubList.push(newSub);
    return newSubList;
  };
  const deleteSub = (state, action) => {
    const oldSub = action.payload;

    const newSubList = state.data.filter(item => item.id !== oldSub);
    
    return newSubList;
  };
  const updateSub = (state, action) => {
    const subId = action.id;

    const newSubList = state.data.slice(0);
    const SubIndex = newSubList.findIndex(subs => subs.id === subId);

    newSubList[SubIndex] = action.payload;
    return newSubList;
  };
  
  const addBundle = (state, action) => {
    const newItem = action.payload;

    const newList = state.bundles.slice(0);
    newList.push(newItem);
    return newList;
  };
  const deleteBundle = (state, action) => {
    const oldItem = action.payload;

    const newList = state.bundles.filter(item => item.id !== oldItem);
    
    return newList;
  };
  const updateBundle = (state, action) => {
    const bundleId = action.id;

    const newList = state.bundles.slice(0);
    const bIndex = newList.findIndex(item => item.id === bundleId);

    newList[bIndex] = action.payload;
    return newList;
  };

   switch (action.type) {
      case FETCH_SUBSCRIPTIONS:
        return {
            ...state,
            data: action.payload,
            total: action.total,
        }
      case ADD_SUBSCRIPTION: 
        return{
          ...state,
          data: addSub(state,action)
        }
      case DELETE_SUBSCRIPTIONS: 
        return{
          ...state,
          data: deleteSub(state,action)
        }
      case UPDATE_SUBSCRIPTIONS:
        return{
          ...state,
          data: updateSub(state,action)
        }
      case FETCH_BUNDLES:
        return{
          ...state,
          bundles: action.payload
        }
      case ADD_BUNDLE: 
        return{
          ...state,
          bundles: addBundle(state,action)
        }
      case DELETE_BUNDLE: 
        return{
          ...state,
          bundles: deleteBundle(state,action)
        }
      case EDIT_BUNDLE:
        return{
          ...state,
          bundles: updateBundle(state,action)
        }
      case ENV_CHANGE:
      case LOGOUT_USER:
        return INITIAL_STATE;
     default:
       return state;
   }
 };